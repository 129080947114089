/* Media queries */
/* Colors */
:root {
  --color-white: #fff;
  --color-black: #000;
  --color-black-shadow: rgba(0, 0, 0, 0.4);
  --color-black-backdrop: rgba(0, 0, 0, 0.3);
  --color-primary: #031d2e;
  --color-primary-light-1: #9acceb;
  --color-primary-medium-1: #1870a7;
  --color-primary-medium-2: #0d5e91;
  --color-primary-dark-1: #034269;
  --color-primary-dark-2: #042b44;
  --color-primary-dark-3: #01121d;
  --color-gray-light-1: #faf9f9;
  --color-gray-light-2: #f4f2f2;
  --color-gray-light-3: #f0eeee;
  --color-gray-light-4: #d5d5d5;
  --color-gray-light-5: #aaa;
  --color-gray-light-6: #999;
  --color-gray-disabled: #04314d;
  --color-ui-blue: #0f6de7;
  --color-ui-red: #e70f0f;
  --color-ui-yellow: #c2bf0b;
  --color-ui-green: #088523;
  --color-ui-orange: #db8514;
  --color-ui-purple: #7a0492;
  --color-ui-highlight: #9bd346; }

/* Default font size */
/* Max width of content area */
/* Breakpoints */
/* Page gutters */
/* Reset */
* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  background-image: linear-gradient(to left top, var(--color-primary-dark-2), var(--color-primary-dark-3));
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.header, .main, .footer {
  padding: 0 4rem; }
  @media only screen and (max-width: 600px) {
    .header, .main, .footer {
      padding: 0 3rem; } }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: var(--color-white); }

.bold {
  font-weight: 700; }

h1 {
  font-size: 2.8rem;
  line-height: 3.5rem;
  margin: 0 auto;
  text-align: center; }
  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 2.4rem; } }

h2 {
  font-size: 2.5rem;
  line-height: 3.5rem; }
  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 2.2rem; } }

h3 {
  font-size: 2rem;
  margin-bottom: 1.2rem; }
  @media only screen and (max-width: 600px) {
    h3 {
      font-size: 1.8rem; } }

h4 {
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: .6rem; }
  @media only screen and (max-width: 600px) {
    h4 {
      font-size: 1.6rem; } }

p {
  font-size: 1.6rem;
  margin-bottom: 2rem; }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 1.4rem; } }

a {
  color: var(--color-white);
  text-decoration: none; }

ul {
  margin: 0 0 3rem 3rem; }
  ul li {
    font-size: 1.6rem;
    margin-bottom: .8rem; }
    @media only screen and (max-width: 600px) {
      ul li {
        font-size: 1.4rem; } }

.icomoon-icon {
  width: 2.2rem; }

.u-margin-bottom-small {
  margin-bottom: 1.7rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 3rem !important; }

.u-margin-bottom-large {
  margin-bottom: 4.5rem !important; }

/* Board */
.board {
  width: 55rem;
  height: 55rem;
  margin: 0 auto;
  box-shadow: 0.2rem 0.2rem 1rem var(--color-black-shadow);
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(8, 1fr); }
  @media only screen and (max-width: 900px) {
    .board {
      width: 45rem;
      height: 45rem; } }
  @media only screen and (max-width: 600px) {
    .board {
      width: 35rem;
      height: 35rem; } }

/* Square */
.square {
  display: flex;
  justify-content: center;
  align-items: center; }
  .square--legal {
    cursor: pointer; }

/* Scores */
@media only screen and (max-width: 900px) {
  .scores__title {
    text-align: center; } }

.scores__player {
  display: flex;
  height: 100%;
  margin-bottom: 1rem; }
  .scores__player-bar {
    align-self: stretch;
    flex: 0 0 1rem; }
  .scores__player-name {
    background-color: var(--color-primary-dark-2);
    flex: 1;
    font-size: 1.6rem;
    margin: 0;
    padding: 1rem; }
  .scores__player-score {
    flex: 0 0 4.5rem;
    background-color: var(--color-primary-dark-1);
    font-size: 1.6rem;
    text-align: center;
    margin: 0;
    padding: 1rem; }

.setup {
  width: 100%;
  text-align: center; }
  .setup__ui {
    display: inline-block; }
    @media only screen and (max-width: 600px) {
      .setup__ui {
        display: block; } }
    .setup__ui h2 {
      height: 3.2rem; }
    .setup__ui .choose-name {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.5rem;
      padding: .5rem;
      margin: 1.5rem 0 1.8rem 0; }
    .setup__ui .choose-color {
      width: 100%;
      display: grid;
      justify-items: center;
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(6, 1fr); }
      @media only screen and (max-width: 600px) {
        .setup__ui .choose-color {
          grid-auto-rows: 5rem;
          grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
          row-gap: 1.5rem; } }
      .setup__ui .choose-color__disc {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        margin: 0 1rem;
        box-shadow: 0 0 1rem var(--color-black-shadow);
        cursor: pointer; }
      .setup__ui .choose-color input {
        display: none; }
        .setup__ui .choose-color input:checked + label .choose-color__disc {
          border: 4px solid #fff; }
        .setup__ui .choose-color input[disabled] + label .choose-color__disc {
          background-color: var(--color-gray-disabled) !important;
          cursor: auto; }
  .setup__player {
    width: 100%;
    background-color: var(--color-primary-dark-2);
    margin-bottom: 2rem;
    padding: 2.5rem 3rem 3rem 3rem; }
    .setup__player:last-child {
      margin: 0; }
  .setup__submit .button {
    color: var(--color-primary-dark-3);
    background-color: var(--color-gray-light-1); }
  .setup__alert {
    height: 2rem;
    margin-top: 1rem; }
    .setup__alert p {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--color-ui-red); }

/* Status bar */
.status__header {
  text-align: center; }

/* Symbols */
.symbols {
  display: flex;
  justify-content: center; }
  .symbols__container {
    background-color: #009900; }
  .symbols__text {
    font-size: 1.6rem; }

/* Container div */
.container {
  max-width: 100rem;
  margin: 0 auto; }

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  padding-top: 3rem;
  padding-bottom: 3rem; }

/* Main */
.main {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.white {
  color: var(--color-primary-dark-3);
  box-shadow: inset 0 0 0.8rem rgba(0, 0, 0, 0.5);
  background-color: var(--color-white);
  background-image: url(svg/background-circles.svg); }
  .white a, .white a:hover, .white a:visited {
    color: var(--color-primary-dark-3); }
  .white svg {
    fill: var(--color-primary-dark-3); }

/* Footer */
.footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center; }
  .footer__blurb {
    max-width: 50rem;
    margin: 0 auto 1.5rem auto; }
    .footer__blurb p {
      font-size: 1.3rem; }
      @media only screen and (max-width: 600px) {
        .footer__blurb p {
          font-size: 1.1rem; } }
  .footer__copyright p {
    font-size: 1.2rem; }
    @media only screen and (max-width: 600px) {
      .footer__copyright p {
        font-size: 1rem; } }

/* Game area styles */
.game {
  display: flex; }
  @media only screen and (max-width: 900px) {
    .game {
      display: block; } }
  .game .sidebar {
    flex: 1 1;
    margin-right: 3rem; }
    @media only screen and (max-width: 900px) {
      .game .sidebar {
        margin-right: 0; } }
    .game .sidebar__section {
      margin: 0 auto 4rem auto; }
      @media only screen and (max-width: 900px) {
        .game .sidebar__section {
          width: 45rem; } }
      @media only screen and (max-width: 600px) {
        .game .sidebar__section {
          width: 35rem; } }
  .game .table {
    flex: 3 1;
    min-height: 5rem; }

.home__header h2, .home__description h2 {
  text-align: center; }

.home__description {
  max-width: 70rem;
  margin: 0 auto; }
  .home__description h2 {
    margin-bottom: 2.5rem; }
  .home__description p {
    margin-bottom: 2.5rem; }

.rules h2 {
  text-align: center;
  margin-bottom: 2.5rem; }

.rules__content {
  max-width: 70rem;
  margin: 0 auto 4.5rem auto; }

.rules__img {
  text-align: center; }
  .rules__img img {
    width: 70%;
    margin-bottom: 2rem; }
    @media only screen and (max-width: 600px) {
      .rules__img img {
        width: 90%; } }

.rules .icomoon-icon {
  width: 1.8rem; }

/* Backdrop */
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  transition: all 0.5s;
  background-color: var(--color-black-backdrop);
  backdrop-filter: blur(4px); }

.button {
  border: 0;
  outline: none;
  padding: 1rem 1.8rem 1.2rem 1.8rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s ease-out; }
  @media only screen and (max-width: 600px) {
    .button {
      font-size: 1.8rem; } }
  .button:hover {
    background-color: var(--color-primary-light-1); }
  .button[disabled] {
    background-color: var(--color-gray-light-6);
    cursor: auto; }

/* Home UI button styles */
.home-ui__row,
.home-ui__button,
.home-ui__button--disc {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 600px) {
    .home-ui__row,
    .home-ui__button,
    .home-ui__button--disc {
      flex-direction: column; } }

.home-ui {
  text-align: center;
  margin: 0 auto; }
  .home-ui__button {
    background-color: var(--color-primary-dark-2);
    cursor: pointer;
    width: 22rem;
    height: 22rem;
    margin: 0 1rem; }
    @media only screen and (max-width: 600px) {
      .home-ui__button {
        width: 20rem;
        height: 20rem;
        margin: 0 1rem 2rem 1rem; } }
    .home-ui__button--disc {
      color: var(--color-white); }
    .home-ui__button:hover .home-ui__button--disc {
      background-color: var(--color-primary-light-1); }
      .home-ui__button:hover .home-ui__button--disc h2 {
        color: var(--color-black);
        text-shadow: none; }
    .home-ui__button--disc,
    .home-ui__button--disc h2 {
      transition: all 0.5s ease; }
    .home-ui__button--disc {
      width: 80%;
      height: 80%;
      border-radius: 50%;
      box-shadow: 0 0 1rem var(--color-black-shadow); }
      .home-ui__button--disc-1 {
        background-color: var(--color-ui-green); }
      .home-ui__button--disc-2 {
        background-color: var(--color-primary-medium-2); }
      .home-ui__button--disc-3 {
        background-color: var(--color-ui-purple); }
      .home-ui__button--disc h2 {
        text-shadow: 1px 1px 1px var(--color-black); }

/* Disc */
.disc {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  box-shadow: 0 0 1rem var(--color-black-shadow); }
  .disc--legal {
    background-color: var(--color-gray-light-6);
    width: 10%;
    height: 10%; }

/* Modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
  z-index: 500;
  background-image: linear-gradient(to bottom right, var(--color-white), var(--color-gray-light-3));
  box-shadow: 2rem 2rem 2rem var(--color-black-shadow);
  padding: 2.5rem;
  color: var(--color-primary-dark-2);
  text-align: center;
  width: 70rem; }
  @media only screen and (max-width: 900px) {
    .modal {
      width: 85%; } }
  .modal h2 {
    margin-bottom: 2.8rem; }
  .modal h3 {
    font-size: 2.4rem;
    margin-bottom: 0; }
  .modal ul {
    list-style: none;
    margin: 0; }
  .modal li {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 0; }
  .modal__game-over {
    margin-bottom: 2.2rem; }
  .modal__buttons {
    display: flex;
    justify-content: center;
    margin-top: 3.2rem; }
    @media only screen and (max-width: 900px) {
      .modal__buttons {
        flex-direction: column;
        align-items: center;
        width: 100%; } }
    .modal__buttons .button {
      margin: 0 1rem;
      color: var(--color-white);
      background-color: var(--color-primary-dark-1); }
      @media only screen and (max-width: 900px) {
        .modal__buttons .button {
          width: 23rem;
          margin-bottom: 2rem; } }
      .modal__buttons .button:hover {
        color: var(--color-primary-dark-3);
        background-color: var(--color-primary-light-1); }
